#root {
    .basket-grid {
        @media screen and (max-width: 767px) {
            display: none;
        }

        &--mobile {
            display: block;
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }

    .basket {
        position: sticky;
        top: 120px;

        .phone {
            font-size: 25px;
            &:before {
                padding-right: 10px;
                color: #dd040c;
                font-family: 'base-icons';
                content: '\e901';
            }
            a {
                color: #dd040c;
            }
        }
    }

    .button.button--small {
        position: relative;
        top: -2px;
        display: inline-block;
        height: auto;
        margin-bottom: 5px;
        margin-left: 5px;
        padding: 5px 8px;
        border-radius: 5px;
        font-size: 12px;
        line-height: 1.1;
    }

    .percentage {
        position: relative;
        display: block;
        width: 100%;
        height: 20px;
        margin-bottom: 40px;
        border: 1px solid darken(#dd040c, 5%);
        border-radius: 5px;
        font-size: 17px;
        transition: border 0.2s ease;

        .percentage__indicator {
            position: absolute;
            top: 0;
            left: 62.5%;
            display: block;
            width: 1px;
            height: calc(100% + 15px);
            background-color: #000;

            &::after {
                content: attr(data-indicator);
                position: relative;
                top: calc(100% + 5px);
                display: block;
                width: 92px;
                font-size: 12px;
                transform: translateX(-50%);
            }
        }

        &.percentage--red {
            border: 1px solid darken(#dd040c, 5%);

            > div {
                background-color: #dd040c;
            }
        }

        &.percentage--green {
            border: 1px solid darken(#297825, 5%);

            > div {
                background-color: #297825;
            }
        }

        > div > div,
        &::before {
            content: attr(data-percentage);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            text-align: center;
        }

        > div {
            display: block;
            overflow: hidden;
            width: 0;
            height: 100%;
            background-color: #297825;
            transition:
                width 0.5s ease,
                background-color 0.2s ease;

            > div {
                z-index: 5;
                color: white;
                transition: clip-path 0.5s ease;
            }
        }
    }
}
