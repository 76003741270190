#root {
    $spaces: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60);
    --malteser-red: #dd040c;

    @each $space in $spaces {
        .m-#{$space} {
            margin: #{$space}px;
        }

        .mt-#{$space} {
            margin-top: #{$space}px;
        }

        .mb-#{$space} {
            margin-bottom: #{$space}px;
        }

        .p-#{$space} {
            padding: #{$space}px;
        }

        .pt-#{$space} {
            padding-top: #{$space}px;
        }

        .pb-#{$space} {
            padding-bottom: #{$space}px;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }

    .link-style {
        padding: 0;
        border: none;
        background-color: transparent;
        color: var(--malteser-red);
        font-size: 18px;
        font-family: 'Roboto Condensed', Arial, sans-serif;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .group-container {
        display: flex;
        justify-content: space-between;
    }

    .sigCanvas {
        width: 100%;
        max-width: 500px;
    }

    .button--form {
        margin-right: 10px;
    }

    .button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .button.button--green {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        transition:
            background 0.2s,
            color 0.2s,
            box-shadow 0.2s;

        &:hover:not(:disabled) {
            background-color: white;
            color: inherit;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        }
    }

    .error {
        color: #dd040c;
        font-weight: bold;
    }

    .grid {
        min-width: 100%;
    }

    p.small {
        margin-top: 20px;
        font-size: 14px;
    }
}

.main-content {
    overflow: unset !important;
}

.serviceInfo--open {
    height: 380px !important;

    @media (max-width: 1024px) {
        height: 800px !important;
    }

    @media (min-width: 551px) and (max-width: 1000px) {
        height: 500px !important;
    }
}
