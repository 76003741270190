#root {
    .grid__column.grid__column--lg-6 {
        container: productItem / inline-size;
    }

    .product {
        display: flex;
        flex: 1 0 auto;
        margin-bottom: 20px;
        padding-top: 20px;
        border: 1px solid rgba(0, 0, 0, 0.1);

        &__image {
            flex: 0 0 150px;
            margin-left: 20px;

            img {
                display: block;
                flex: 0 0 auto;
                width: 150px;
                border: 1px solid #ededed;
            }
        }

        &__content {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            margin-left: 20px;
            padding-right: 15px;
        }

        &__title {
            margin-bottom: 0;
        }

        &__description {
            margin-bottom: 0;

            ul li {
                padding-left: 16px;
            }

            & + .product__count {
                margin-top: 10px;
            }
        }

        &__count {
            display: flex;
            flex: 1 0 auto;
            align-items: flex-end;
            margin-bottom: 10px;
        }
    }
}

@container productItem (max-width: 420px) {
    .product {
        flex-direction: column;
        .product__image {
            flex: 0 0 auto !important;
            margin-bottom: 20px;
        }
    }
}
