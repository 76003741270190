#root {
    form {
        display: inline !important;
        width: 100%;
    }

    .page-pagination {
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: flex-start;
        padding-top: 10px;
        background-color: #fff;

        @media screen and (min-width: 500px) {
            flex-direction: row;
        }

        .button {
            margin-right: 0;
        }

        .menuservice-orderform-buttonwrapper--step1 {
            position: unset;
        }
    }

    .menuservice-orderform-step-indicator {
        margin-bottom: 80px;
    }

    .menuservice-orderform-step-indicator-step-info,
    .menuservice-orderform-step-indicator-step-wrapper,
    .menuservice-orderform-step-indicator-step-number {
        transition:
            border 0.5s ease,
            color 0.5s ease,
            background 0.5s ease;
    }

    .menuservice-orderform-step-indicator-step {
        position: relative;
        background-color: transparent;

        &.menuservice-orderform-step-indicator-step--active {
            cursor: inherit;
        }
    }

    .menuservice-orderform-step-indicator-step-info {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
